body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, #root {
  height: 100%;
  background-color: #F0F0F0;
  overflow: 'hidden';
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}

body.Login {
  height: 100%;
  background-color: #F6F6F2;
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  #root {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  #root {
    font-size: 18px;
  }
}